import React, { useReducer, useState, memo } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import classNames from 'classnames'
import styled from "styled-components";
import {
    composeWithDisplayName,
    createWithMountCondition,
    omitProps,
    withUnmountWhenHidden
} from '../../../reactcomponents/hocs'
import { I, webRoot } from '../../../common/v5/config';
import SquareCheckbox from "../../../reactcomponents/SquareCheckbox";
import {
	LabelToggleSwitch as ToggleSwitch
} from '../../../components/v5/ToggleSwitch';

const List = React.memo(({ list, isDraggingOver, columnId, onItemClick, itemField, onItemToggle, ...props }) => {
	if (typeof list === 'undefined') {
		return null;
	}
    return list.map((listItem, index) => (
        <Item
            isDraggingOver={isDraggingOver}
            key={listItem.id}
            index={index}
            columnId={columnId}
            onItemClick={onItemClick}
			onItemToggle={onItemToggle}
            itemLabel={listItem[itemField]} //label follows the 'item field' that was stated
            {...listItem}
            {...props}
        />
    ));
});
List.displayName = 'List'; //inspect/debug name

const Board = React.memo(({ id, title, list, onItemClick, onItemToggle, ...props }) => {
    return (
        <div className={classNames("board__container", { "disabled-items": props.disableSelect })}>
            <div className="board__title" title={title}>{title}</div>
            <Droppable droppableId={id}>
                {(provided, snapshot) => {
                    return (
                        // <BoardBody className={"board__body"}
                        <div className={classNames("board__body", { "isDraggingOver": snapshot.isDraggingOver })}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        // isDraggingOver={snapshot.isDraggingOver}
                        >
                            <div className="board__body-inner-wrap">
                                <List
                                    isDraggingOver={snapshot.isDraggingOver}
                                    list={list}
                                    columnId={id}
                                    onItemClick={onItemClick}
                                    onItemToggle={onItemToggle}
                                    {...props}
                                />
                                {provided.placeholder}
                            </div>
                        </div>
                    );
                }}
            </Droppable>
        </div>
    );
});
Board.displayName = 'Board'; //inspect/debug name

function getStyle(style, snapshot) {
    if (!snapshot.isDropAnimating) {
        return style;
    }

    // patching the existing style
    return {
        ...style,
        transition: `all 0.2s ease`,
        transitionDelay: "0.2s",
        // backgroundColor: "#0c87f733"
    };
}
const Item = React.memo(({ index, id, name, columnId, onItemClick, itemLabel, onItemToggle, ...props }) => {

    const handleTick = (check, id) => {
        onItemClick(id, columnId)
    }
    const handleToggle = (value, id) => {
		console.info("handleToggle:", value, id);
        onItemToggle(value, id)
    }
    let isChecked = props.checkedItems.some(item => item.id === id)

    return (
        //draggableId needs to be "string" format
        <Draggable draggableId={id.toString()} index={index} isDragDisabled={props.disableSelect}>
            {(provided, snapshot) => (
                <div className={classNames("item", { "isDragging": (snapshot.isDragging && !snapshot.isDropAnimating) })}
                    value={id}
                    ref={provided.innerRef}
                    // onClick={onItemClick.bind(null, id, columnId)}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    // isDragging={snapshot.isDragging && !snapshot.isDropAnimating}
                    style={getStyle(provided.draggableProps.style, snapshot)}
                >
                    <div className="item__content">
                        {(props.checkedItems.length > 1 && (snapshot.isDragging && !snapshot.isDropAnimating))
                            ?
                            <div className="item__multiple-selection"><p className="label">{props.totalSelected} {I("selected")}</p></div>
                            :
                            <div className="item__details">
                                <i className="icon-grip"></i>
                                {props.checkBox == true &&
                                    <SquareCheckbox
                                        className={"v5-checkbox big"}
                                        data-qa-id={id}
                                        checked={isChecked}
                                        onClick={handleTick}
                                        label={itemLabel}
                                    />}
                                {props.useToggle == true &&
                                    <ToggleSwitch
										id={id}
                                        data-qa-id={id}
                                        checked={props.enabled}
                                        onClick={handleToggle}
                                        label={itemLabel}
                                    />}
                            </div>
                        }
                    </div>
                </div>
            )}
        </Draggable>
    );
});
Item.displayName = "Item"

const KanbanBoard = ({ data, onDragStart, onDragEnd, onItemClick, onItemToggle, ...props }) => {

    return (
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
            <div id="DragDropKanban" className="dragDrop-context">
                {Object.keys(data).map((objectKey, i) => ( //loop through object key/fields
                    <Board
                        key={objectKey}
                        id={objectKey}
                        onItemClick={onItemClick}
                        onItemToggle={onItemToggle}
                        activeBoard={objectKey === props.selectedBoard}
                        disableSelect={(objectKey !== props.selectedBoard) && props.hasItemSelected}
                        {...data[objectKey]} //spread all its properties
                        {...props}
                    />
                ))}
            </div>
        </DragDropContext>
    );
};

export default KanbanBoard;
